<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="onComposeClick()"
            >
              Compose
            </b-button>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{
                  filter.title
                }}</span>
              </b-list-group-item>
            </b-list-group>

            <b-list-group class="list-group-messages mt-4">
              <b-list-group-item
                v-for="tab in emailTabs"
                :key="tab.title + $route.path"
                :to="tab.route"
                :active="isDynamicRouteActive(tab.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="tab.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{
                  tab.title
                }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="label in emailLabel"
                :key="label.title + $route.path"
                :to="label.route"
                :active="isDynamicRouteActive(label.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${label.color}`"
                />
                <span>{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BButton, BListGroup, BListGroupItem } from "bootstrap-vue";
import { isDynamicRouteActive } from "@core/utils/utils";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onComposeClick() {
      this.$emit("update:shall-show-email-compose-modal", true);
      this.$emit("close-left-sidebar");
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const emailFilters = [
      {
        title: "Inbox",
        icon: "MailIcon",
        route: { name: "participant-messages" },
      },
    ];

    const emailTabs = [
      {
        title: "Sent",
        icon: "SendIcon",
        route: { name: "participant-sent" },
      },
      {
        title: "Undelivered",
        icon: "AlertCircleIcon",
        route: { name: "participant-undelivered" },
      },
    ];

    const emailLabel = [];

    const resolveFilterBadgeColor = filter => {
      if (filter === "Draft") return "light-warning";
      if (filter === "Spam") return "light-danger";
      return "light-primary";
    };

    return {
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,
      emailFilters,
      emailLabel,
      emailTabs,
    };
  },
};
</script>
